<mat-progress-bar
  color="primary"
  *ngIf="isLoading"
  mode="indeterminate"
></mat-progress-bar>
<div class="container">
  <div class="info">
    <p class="italic">*Mandatory</p>
    <a href="/assets/eTAR_External_Web_tool_Tip_Sheet_mh.docx" class="link"
      >Having Problems or Need Help? Click here to View Tip Sheet</a
    >
  </div>
  <mat-toolbar [class.mat-elevation-z2]="true">
    <mat-toolbar-row>
      <span>Licensee/Supplier Information</span>
    </mat-toolbar-row>
  </mat-toolbar>
  <form [formGroup]="form" novalidate>
    <mat-card class="supplier-form">
      <div class="mydiv">
        <!-- BEST Code -->

        <mat-form-field class="col-6">
          <mat-label>BEST Code</mat-label>
          <input
            type="text"
            formControlName="bestCode"
            placeholder="Enter site/CAGE code or ‘NA’ if unknown"
            matInput
            required
          />
        </mat-form-field>
        <!-- Company Name -->
        <mat-form-field class="col-6">
          <mat-label>Company Name</mat-label>
          <input type="text" formControlName="companyName" matInput required />
        </mat-form-field>

        <!-- Contact Name -->
        <mat-form-field class="col-6">
          <mat-label>Contact Name</mat-label>
          <input type="text" formControlName="contactName" matInput required />
        </mat-form-field>

        <!-- Email Address -->
        <mat-form-field class="col-6">
          <mat-label>Email Address</mat-label>
          <input
            type="email"
            name="email"
            email
            formControlName="emailAddress"
            emailAddress="Enter email or 'NA' if unknown"
            matInput
            required
          />
        </mat-form-field>

        <!-- Phone Number -->
        <mat-form-field class="col-6">
          <mat-label>Phone Number</mat-label>
          <input type="text" formControlName="phoneNumber" matInput required />
        </mat-form-field>

        <!-- PA Email Address -->
        <mat-form-field class="col-6">
          <mat-label>Procurement Agent Email Address</mat-label>
          <input
            type="email"
            name="email"
            ngModel
            formControlName="paemail"
            matInput
            required
            placeholder="Enter email or 'NA' if unknown"
          />
          <mat-error>
            Enter email of a procurement agent from boeing or 'NA' if unknown
          </mat-error>
        </mat-form-field>

        <!-- Address 1  -->
        <mat-form-field class="col-6">
          <mat-label>Address 1</mat-label>
          <input type="text" formControlName="address1" matInput required />
        </mat-form-field>

        <!-- Address 2 -->
        <mat-form-field class="col-6">
          <mat-label>Address 2</mat-label>
          <input type="text" formControlName="address2" matInput />
        </mat-form-field>

        <!-- City -->
        <mat-form-field class="col-6">
          <mat-label>City</mat-label>
          <input type="text" formControlName="city" matInput required />
        </mat-form-field>

        <!-- Country Dropdown -->
        <mat-form-field class="col-6">
          <mat-select
            class="country-dropdown-field"
            formControlName="country"
            placeholder="Select Country"
            [(ngModel)]="defaultCountry"
            required
          >
            <mat-option>-Select Country-</mat-option>
            <mat-option *ngFor="let c of countryData" [value]="c.name">{{
              c.name
            }}</mat-option>
          </mat-select>
        </mat-form-field>

        <!-- State Dropdowns -->
        <mat-form-field class="col-6" *ngIf="defaultCountry === 'USA'">
          <mat-label>State/Province</mat-label>
          <mat-select
            class="country-dropdown-field"
            formControlName="stateprovince"
            placeholder="Select State"
            required
          >
            <mat-option>-Select State-</mat-option>
            <mat-option *ngFor="let c of usStates" [value]="c.code">{{
              c.code
            }}</mat-option>
          </mat-select>
        </mat-form-field>
        <mat-form-field class="col-6" *ngIf="defaultCountry === 'Canada'">
          <mat-label>State/Province</mat-label>
          <mat-select
            class="country-dropdown-field"
            formControlName="stateprovince"
            placeholder="Select Province"
            required
          >
            <mat-option>-Select Province-</mat-option>
            <mat-option *ngFor="let c of canadaProvinces" [value]="c.name">{{
              c.name
            }}</mat-option>
          </mat-select>
        </mat-form-field>
        <mat-form-field
          *ngIf="defaultCountry != 'USA' && defaultCountry != 'Canada'"
          class="col-6"
        >
          <mat-label>State/Province</mat-label>
          <input
            type="text"
            formControlName="stateprovince"
            matInput
            required
          />
        </mat-form-field>

        <!-- Postal Code -->
        <mat-form-field class="col-6">
          <mat-label>Postal Code</mat-label>
          <input type="text" formControlName="postalCode" matInput required />
        </mat-form-field>

        <!-- Customer Effectivity -->
        <mat-form-field class="col-6">
          <mat-label>Customer and Effectivity</mat-label>
          <input
            type="text"
            formControlName="customer_And_Effectivity"
            matInput
          />
        </mat-form-field>
      </div>
    </mat-card>
    <p class="italic">
      Please copy and paste parts from an excel file or enter it into the table
      below. Please press enter to add additional rows.
    </p>
    <mat-card class="parts-table">
      <div class="d-flex d-flex justify-content-center" #spreadsheet></div>
    </mat-card>

    <div>
      <span class="italic">** Model field is limited to 500 characters.</span><br>
      <span class="italic">* Mandatory.</span>
      <br /><br />
    </div>

    <div class="container">
      <div class="row justify-content-center align-content-center captcha">
        <div #captchaContainer></div>
        <button
          mat-icon-button
          aria-label="refresh captcha"
          (click)="refreshCaptcha()"
        >
          <mat-icon>sync</mat-icon>
        </button>
      </div>
      <div class="row">
        <mat-form-field class="offset-3 col-6 align-self-center">
          <mat-label>Type the characters you see in the image</mat-label>
          <input type="text" formControlName="captcha" matInput required />
          <mat-error>Invalid captcha</mat-error>
        </mat-form-field>
      </div>
    </div>
  </form>
  <div class="form-actions">
    <button
      class="col-2"
      [disabled]="!form.valid"
      mat-raised-button
      color="primary"
      (click)="submitForm()"
    >
      SUBMIT
    </button>
    <button class="col-2" mat-button color="accent" (click)="resetForm(true)">
      RESET
    </button>
  </div>
</div>
<mat-progress-bar
  color="primary"
  *ngIf="isLoading"
  mode="indeterminate"
></mat-progress-bar>
<ng-template #resetDialog>
  <div mat-dialog-title>Confirm Reset</div>
  <mat-dialog-content
    >Changes you made so far will not be saved. Do you wish to
    continue?</mat-dialog-content
  >
  <br />
  <mat-dialog-actions align="center">
    <button mat-flat-button color="warn" mat-dialog-close="reset">Reset</button>
    <button mat-flat-button mat-dialog-close="close">Cancel</button>
  </mat-dialog-actions>
</ng-template>
