<div *ngIf="!isIE()" [class]="'theme-wrapper ' + (theme$ | async)">
  <mat-sidenav-container>
    <div class="wrapper">
      <!-- navbar -->
      <ef-navbar></ef-navbar>

      <!-- main content  -->
      <div
        class="content"
        [@routeAnimations]="
          o.isActivated &&
          o.activatedRoute.routeConfig.data &&
          o.activatedRoute.routeConfig.data.title
        "
      >
        <!-- router outlet -->
        <router-outlet #o="outlet"></router-outlet>
      </div>

      <!-- footer -->
      <ef-footer></ef-footer>
    </div>
  </mat-sidenav-container>
</div>
<div *ngIf="isIE()" class="ie-message">
  Your browser is not supported. Please use Mozilla Firefox® 60.x or higher.
</div>
