<mat-toolbar color="white" class="navbar-container" [class.mat-elevation-z4]="true">
    <div class="logo"><img src="../../../assets/boeing_logo_blue.png" alt="" /></div>
    <span class="spacer"></span>
    <div class="org-name">
      <div class="primary">
        eTAR (Electronic Tech Assist Request) Input Form
      </div>
    </div>
</mat-toolbar>

