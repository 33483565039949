<mat-dialog-content>
  <p>
    {{ message }}
  </p>
</mat-dialog-content>
<mat-dialog-actions>
  <button
    mat-raised-button
    color="primary"
    (click)="onConfirmClick()"
    tabindex="1"
  >
    {{ confirmButtonText }}
  </button>
</mat-dialog-actions>
