import { Component, OnInit, ChangeDetectionStrategy } from '@angular/core';
import { environment as env } from '../../../environments/environment';
@Component({
  selector: 'ef-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FooterComponent implements OnInit {
  isProd = env.production;
  envName = env.envName;
  version = env.versions.app;
  year = new Date().getFullYear();
  constructor() {}

  ngOnInit(): void {}
}
