import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";
import { EtarInputFormComponent } from "./etar-input-form/etar-input-form.component";

const routes: Routes = [
  {
    path: "",
    component: EtarInputFormComponent,
    data: { title: "Home" },
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class HomeRoutingModule {}
