import browser from "browser-detect";
import { Component, OnInit } from "@angular/core";
import { Store, select } from "@ngrx/store";
import { Observable } from "rxjs";

import {
  routeAnimations,
  LocalStorageService,
  selectEffectiveTheme,
} from "../core/core.module";
import { actionSettingsChangeAnimationsPageDisabled } from "../core/settings/settings.actions";

@Component({
  selector: "ef-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.scss"],
  animations: [routeAnimations],
})
export class AppComponent implements OnInit {
  logo = require("../../assets/boeing_logo_white.png").default;
  stickyHeader$: Observable<boolean>;
  theme$: Observable<string>;

  constructor(
    private store: Store,
    private storageService: LocalStorageService
  ) {}

  private static isIEorEdgeOrSafari() {
    return ["ie", "edge", "safari"].includes(browser().name);
  }
  isIE() {
    return browser().name === "ie";
  }

  ngOnInit(): void {
    // Storage Service Call
    this.storageService.testLocalStorage();

    // Disable animations on IE, Edge and Safari if detected.
    if (AppComponent.isIEorEdgeOrSafari()) {
      this.store.dispatch(
        actionSettingsChangeAnimationsPageDisabled({
          pageAnimationsDisabled: true,
        })
      );
    }

    // TODO : Fix Sticky Header
    // this.stickyHeader$ = this.store.pipe(select(selectSettingsStickyHeader));
    this.theme$ = this.store.pipe(select(selectEffectiveTheme));
  }
}
