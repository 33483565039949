import { Component, OnInit, ChangeDetectionStrategy, Input } from '@angular/core';

@Component({
  selector: 'ef-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NavbarComponent implements OnInit {
  @Input() lineOne = 'Boeing Angular Reference Template';
  @Input() lineTwo = 'BSCS Cloud Team';
  logo = require('../../../assets/boeing_logo_white.png').default;

  navigationLinks = [
    { link: 'home', label: 'Home' },
  ];
  constructor() { }

  ngOnInit(): void { }
}
