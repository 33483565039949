<div class="profile-menu" mat-button [matMenuTriggerFor]="menu">
  <div class="profile-info">
    <small class="welcome">Welcome,</small>
    <span class="user-name">Pranshu Jawade</span>
  </div>
  <div class="profile-image">
    <mat-icon class="down-arrow">expand_more</mat-icon>
  </div>
</div>
<mat-menu #menu="matMenu" xPosition="after">
  <button mat-menu-item>2967206</button>
  <button mat-menu-item>pranshu.jawade@boeing.com</button>
  <button mat-menu-item>Mail Code :</button>
</mat-menu>
