import { Moment } from "moment";

export class PartsModel {
  BoeingPartNumber: string;
  Nomenclature: string;
  Model: string;
  Drawing: string;
  RevisionLevel: string;
  RevisionDate: string;
  SupplierPartNumber: string;
}
