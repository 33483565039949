<div class="container">
  <mat-toolbar [class.mat-elevation-z2]="true">
    <mat-toolbar-row class="header">
      <mat-icon aria-hidden="false" aria-label="Example home icon"
        >done</mat-icon
      >
      <span class="header-text">Success</span>
    </mat-toolbar-row>
  </mat-toolbar>
  <mat-card>
      <p>The request has been submitted successfully!</p>
  </mat-card>
</div>
