<div class="footer">
  <div class="row">
    <div class="col-12 signature">
      &#169; <span class="year">{{ year }}</span> - The Boeing Company. All rights reserved.
      <a _ngcontent-pir-c47="" href="mailto:PMAOffice@boeing.com" class="links"> Contact Us.</a>
      <br />
      <span>eTAR (Electronic Tech Assist Request) Input Form is best viewed Mozilla Firefox®
        60.x or higher.</span>
      <br class="d-block d-sm-none" />
      <div class="spacer"></div>
    </div>
  </div>
</div>
