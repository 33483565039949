import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";

@Injectable({
  providedIn: "root",
})
export class EtarPartsService {
  constructor(private http: HttpClient) {}

  httpOptions = {
    headers: new HttpHeaders({ "Content-Type": "application/json" }),
  };

  getCountries() {
    return this.http.get("/api/getCountries");
  }

  getStates(countryCode: number) {
    return this.http.get("/api/getStates/" + countryCode);
  }

  submitFormData(formData: any) {
    return this.http.post(
      "/api/EtarInputForm/SubmitForm",
      JSON.stringify(formData),
      this.httpOptions
    );
  }

  getCaptcha() {
    return this.http.get("/api/captcha", { responseType: "text" });
  }
}
