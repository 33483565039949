import {
  FormBuilder,
  FormControl,
  FormGroup,
  ValidationErrors,
  Validators,
} from "@angular/forms";
import * as _moment from "moment";
import { EtarPartsService } from "../services/etar-parts.service";
import { states } from "../../../models/states";
import { countries } from "../../../models/countries";
import {
  AfterViewInit,
  Component,
  ElementRef,
  OnInit,
  TemplateRef,
  ViewChild,
} from "@angular/core";
import * as jspreadsheet from "jspreadsheet-ce";
import { PartsModel } from "../parts-model";
import { MatDialog } from "@angular/material/dialog";
import { DialogComponent } from "../../../shared/dialog/dialog.component";
import { Router } from "@angular/router";

const moment = _moment;

@Component({
  selector: "ef-etar-input-form",
  templateUrl: "./etar-input-form.component.html",
  styleUrls: ["./etar-input-form.component.scss"],
})
export class EtarInputFormComponent implements OnInit, AfterViewInit {
  @ViewChild("spreadsheet") spreadsheet: ElementRef;
  @ViewChild("resetDialog", { static: true }) resetDialog: TemplateRef<any>;
  isLoading: boolean = false;
  defaultCountry = "USA";
  countryData: countries[] = [];
  usStates: states[] = [];
  canadaProvinces: states[] = [];
  isUSSelected: boolean;
  isCanadaSelected: boolean;
  selectedState: any;
  errorMessage: string;
  captcha: "";
  data: any = [];
  @ViewChild("captchaContainer") captchaContainer: ElementRef;
  table: any;
  columns = ["A", "B", "C", "D", "E", "F", "G"];
  partsTableData: [] = [];
  form: FormGroup = this._fb.group({
    bestCode: ["", [Validators.required, Validators.maxLength(255)]],
    companyName: ["", [Validators.required, Validators.maxLength(255)]],
    contactName: ["", [Validators.required, Validators.maxLength(255)]],
    captcha: ["", [Validators.required, Validators.maxLength(5)]],
    emailAddress: [
      "",
      [
        Validators.required,
        Validators.maxLength(255),
        Validators.pattern(
          /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        ),
      ],
    ],
    phoneNumber: ["", [Validators.required, Validators.maxLength(255)]],
    paemail: [
      "",
      [
        Validators.required,
        Validators.maxLength(255),
        Validators.pattern(/^[a-zA-Z0-9_.+-]+@boeing.com$|na/gi),
      ],
    ],
    address1: ["", [Validators.required, Validators.maxLength(255)]],
    address2: ["", [Validators.maxLength(255)]],
    city: ["", [Validators.required, Validators.maxLength(255)]],
    country: ["", [Validators.required, Validators.maxLength(255)]],
    stateprovince: new FormControl("", [
      Validators.required,
      Validators.maxLength(255),
    ]),
    postalCode: ["", [Validators.required, Validators.maxLength(255)]],
    customer_And_Effectivity: ["", [Validators.maxLength(255)]],
    partList: [],
  });

  constructor(
    private _fb: FormBuilder,
    private _eTarService: EtarPartsService,
    private dialog: MatDialog,
    private router: Router
  ) {}

  ngAfterViewInit(): void {
    this.table = jspreadsheet(this.spreadsheet.nativeElement, {
      data: this.data,
      allowInsertColumn: false,
      allowManualInsertColumn: false,
      allowDeleteColumn: false,
      allowRenameColumn: false,
      columns: [
        { title: "Part Number*", width: 140 },
        { title: "Boeing Part Number", width: 140 },
        { title: "Nomenclature*", width: 140 },
        { title: "Model**", width: 140 },
        { title: "Drawing Number*", width: 140 },
        { title: "Revision Level*", width: 140 },
        {
          type: "calendar",
          title: "Revision Date* (MM/DD/YYYY)",
          options: { format: "MM/DD/YYYY"},
          width: 200,
        },
      ],
      minDimensions: [7, 1],
    });
  }

  ngOnInit() {
    this.getCaptcha();
    this.getCountries();
    this.getUSStates();
    this.getCanadaProvinces();
  }

  getCaptcha() {
    this._eTarService.getCaptcha().subscribe((data) => {
      console.log(this.captchaContainer.nativeElement);
      this.captchaContainer.nativeElement.innerHTML = data;
    });
  }

  getCountries() {
    this._eTarService.getCountries().subscribe((data) => {
      this.countryData = data["_embedded"]["countries"] as countries[];
    });
  }

  getUSStates() {
    this._eTarService.getStates(1).subscribe((data) => {
      let states = data["_embedded"]["states"] as states[];
      states.sort((a, b) => (a.code > b.code ? 1 : -1));
      this.usStates = states;
    });
  }

  getCanadaProvinces() {
    this._eTarService.getStates(39).subscribe((data) => {
      let provinces = data["_embedded"]["states"] as states[];
      provinces.sort((a, b) => (a.name > b.name ? 1 : -1));
      this.canadaProvinces = provinces;
    });
  }

  submitForm() {
    try {
      // Get Parts Data from Table
      this.partsTableData = this.table.getData();
      this.validateAllCells();
      // Populate Parts Data Object to insert in form
      let partsData = this.partsTableData.map((ele) => {
        var part = new PartsModel();
        part.SupplierPartNumber = ele[0];
        part.BoeingPartNumber = ele[1];
        part.Nomenclature = ele[2];
        part.Model = ele[3];
        part.Drawing = ele[4];
        part.RevisionLevel = ele[5];
        part.RevisionDate = ele[6];
        return part;
      });

      // Validate Parts Data
      partsData.forEach((ele) => {
        let isInvalidPart: boolean =
          ele.SupplierPartNumber.length === 0 ||
          ele.Model.length === 0 ||
          ele.Drawing.length === 0 ||
          ele.RevisionLevel.length === 0 ||
          ele.RevisionDate.length === 0;

        if (isInvalidPart)
          throw new Error("One or More Required Parts Table Fields are empty");
        if (ele.Model.length > 500) {
          throw new Error("Model Field contains more than 500 Characters");
        }
      });
      this.isLoading = true;
      // Insert Part Data into Form
      this.form.get("partList").setValue(partsData);
      const inputForm = this.form.value;
      inputForm.countryCode = this.countryData.find(
        (p) => p.name === inputForm.country
      ).id;

      // Call Service to Post Form Data
      this._eTarService.submitFormData(this.form.value).subscribe(
        () => {
          this.isLoading = false;
          this.resetForm(false);
          this.router.navigate(["/success"]);
        },
        (error) => {
          this.isLoading = false;
          if (error.status === 429) {
            this.form.controls["captcha"].setErrors({
              captcha: "Invalid captcha",
            });
          }
          console.log("Error Occured", error);
        }
      );
    } catch (error) {
      const dialogRef = this.dialog.open(DialogComponent, {
        data: {
          message: error,
          buttonText: {
            ok: "Close",
          },
        },
      });
    }
  }

  private validateAllCells() {
    this.clearAllValidations();
    this.partsTableData.forEach((row, index) => {
      this.columns.forEach((column, columnNumber) => {
        if ((row[columnNumber] as string).length === 0) {
          this.table.setStyle({
            [`${column}${index + 1}`]: "background-color: yellow;",
          });
        }
      });
    });
  }

  private clearAllValidations() {
    this.partsTableData.forEach((row, index) => {
      this.columns.forEach((column) => {
        this.table.setStyle({
          [`${column}${index + 1}`]: "background-color: white;",
        });
      });
    });
  }

  resetForm(showPopup) {
    if (!showPopup) {
      this.form.reset();
      this.table.setData([]);
      return;
    }
    const dialogRef = this.dialog.open(this.resetDialog, {
      restoreFocus: true,
    });
    dialogRef.afterClosed().subscribe((action) => {
      if (action === "reset") {
        this.form.reset();
        this.table.setData([]);
      }
    });
  }

  refreshCaptcha() {
    this.getCaptcha();
  }
}
